export enum CONTRACT_FEATURE_TOGGLES {
  PAYPAL_MAINTENANCE = 'contract_ffc_paypal-maintenance',
  SATURN_MAINTENANCE = 'saturn_ffc_maintenancemode',
  CANCELLATION_WITHOUT_LOGIN = 'contract_ffc_cancellation-without-login',
  FORCE_DISPLAY_PARTNER_CONFLICT_RESOLUTION = 'contract_ffc_force_display_partner_conflict_resolution',
  SHOW_TRIAL_DENIAL_INFO = 'contract_ffc_show-trial-denial-info',
  SHOW_LOGOUT_INFO = 'contract_ffc_show-logout-info',
  SHOW_MUSIC_PREFERENCES = 'contract_ffc_show-music-preferences',
  PROGRESSBAR = 'contract_ffc_progressbar',
  CANCELLATION_PATH_AB_TEST = 'contract_ffc_AB_cancellation_path',
}

export enum CUSTOMER_ENGAGEMENT_FEATURE_TOGGLES {
  CHATBOT = 'customer-engagement-ffc-chatbot',
}

export enum IDENTITY_FEATURE_TOGGLES {
  DO_NOT_ENFORCE_EMAIL_VALIDATION = 'identity_ffc_do_not_enforce_email_validation',
  RECEIVE_PASSWORD_VIA_KEYCLOAK = 'identity_ffc_retrieve_password_via_keycloak',
}

export enum OFFERINGS_FEATURE_TOGGLES {
  PRODUCT_SUMMARY_LAYOUT = 'offerings_ffc_product_summary_layout',
  KAMELEOON = 'offerings_ffc_kameleoon',
}

// Experiment Toggles for A/B-Testing with Unleash (https://rtlplus.atlassian.net/wiki/x/R4CnM)
export const EXPERIMENT_TOGGLES: string[] = [
  OFFERINGS_FEATURE_TOGGLES.PRODUCT_SUMMARY_LAYOUT,
  CONTRACT_FEATURE_TOGGLES.CANCELLATION_PATH_AB_TEST,
];
