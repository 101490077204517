import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSubscription from '../reducers/subscription.reducer';

export const selectSubscriptionState = createSelector(
  fromFeature.selectPaymentState,
  (state) => state.subscription,
);

export const selectSubscription = createSelector(
  selectSubscriptionState,
  (state: fromSubscription.SubscriptionState) => state.entity,
);

export const selectSubscriptionLoading = createSelector(
  selectSubscriptionState,
  (state: fromSubscription.SubscriptionState) => state.loading,
);

export const selectSubscriptionLoaded = createSelector(
  selectSubscriptionState,
  (state: fromSubscription.SubscriptionState) => state.loaded,
);
