import { Injectable } from '@angular/core';
import { SignalsStateService } from '../signals.state.service';
import { SubscriberData } from '@nowffc-shared/interfaces/subscriber-data/subscriber-data';
import { SubscriptionService } from '@nowffc-shared/services/subscription/subscription.service';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { isError } from '@nowffc-shared/types/maybe-value';

@Injectable({
  providedIn: 'root',
})
export class SubscriberDataSignalsState extends SignalsStateService<SubscriberData> {
  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly errorsService: ErrorsService,
  ) {
    super();
  }

  protected async init() {
    const res = await this.subscriptionService.getSubscriberData();

    if (isError(res)) {
      this.errorsService.logBugsnag(res.error);
      throw new Error('subscriber.data.generic.loading.error');
    } else {
      this.setState(res.value);
    }
  }

  public async getSubscriberData() {
    if (!this.isInitialized()) {
      await this.init();
    }

    return this.getState();
  }

  public async refreshSubscriberData() {
    await this.init();
  }
}
