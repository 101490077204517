import { Subscription } from '@nowffc-shared/interfaces/subscription';
import { createReducer, on } from '@ngrx/store';
import * as fromSubscription from '../actions/subscription.action';
import { fromAuthentication } from '@nowffc-state/auth';

export interface SubscriptionState {
  entity?: Subscription;
  loaded: boolean;
  loading: boolean;
  error?: { message: string };
}

export const initialState: SubscriptionState = {
  loaded: false,
  loading: false,
  entity: undefined,
};

export const reducer = createReducer(
  initialState,

  // loadSubscription
  on(
    fromSubscription.loadSubscription,
    (state): SubscriptionState => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    fromSubscription.loadSubscriptionFail,
    (state, { error }): SubscriptionState => ({
      ...state,
      loading: false,
      loaded: true,
      entity: undefined,
      error,
    }),
  ),
  on(
    fromSubscription.loadSubscriptionSuccess,
    (state, { subscription }): SubscriptionState => ({
      ...state,
      loading: false,
      loaded: true,
      entity: subscription,
      error: undefined,
    }),
  ),
  on(
    fromAuthentication.logout,
    (): SubscriptionState => ({
      loading: false,
      loaded: false,
      entity: undefined,
    }),
  ),
  on(
    fromAuthentication.loginPartnerSuccess,
    (): SubscriptionState => ({
      loading: false,
      loaded: false,
      entity: undefined,
    }),
  ),
);
