import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as subscriptionAction from '../actions/subscription.action';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { SubscriptionService } from '@nowffc-shared/services/subscription/subscription.service';
import { from, of } from 'rxjs';
import * as fromAuthentication from '@nowffc-state/auth/selectors/authentication.selectors';
import { Store } from '@ngrx/store';
import { AuthService } from '@nowffc-auth/auth.service';

@Injectable()
export class SubscriptionEffects {
  loadSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(subscriptionAction.loadSubscription),
      mergeMap(() =>
        this.store.select(fromAuthentication.selectAuthenticationInitialized),
      ),
      filter((initialized) => initialized),
      switchMap(() => {
        return this.authService.isAuthenticated().pipe(
          switchMap((isLoggedIn) => {
            if (!isLoggedIn) {
              // If the user is not logged in, then we don't need to (and can not) load the subscription.
              return of(
                subscriptionAction.loadSubscriptionSuccess({
                  subscription: undefined,
                }),
              );
            }

            return from(this.subscriptionService.getSubscription()).pipe(
              map((subscription) => {
                if (!subscription) {
                  return subscriptionAction.loadSubscriptionFail({
                    error: { message: 'subscription.undefined' },
                  });
                }

                return subscriptionAction.loadSubscriptionSuccess({
                  subscription,
                });
              }),
              catchError(() =>
                of(
                  subscriptionAction.loadSubscriptionFail({
                    error: {
                      message: 'loading.subscription.failed',
                    },
                  }),
                ),
              ),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly subscriptionService: SubscriptionService,
    private readonly authService: AuthService,
  ) {}
}
