import * as fromSubscription from './subscription.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface PaymentState {
  subscription: fromSubscription.SubscriptionState;
}

export const reducers: ActionReducerMap<PaymentState> = {
  subscription: fromSubscription.reducer,
};

export const selectPaymentState =
  createFeatureSelector<PaymentState>('payment');
