import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, timeout } from 'rxjs/operators';
import { SubscriptionService } from '@nowffc-shared/services/subscription/subscription.service';

/**
 * class IsCustomerActiveGuard
 */
@Injectable({
  providedIn: 'root',
})
export class IsCustomerActiveGuard {
  timeout = 1500;

  /**
   * Wire DI
   */
  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly router: Router,
  ) {}

  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated
   */
  canActivate(): Observable<boolean | UrlTree> {
    return this.subscriptionService.currentSubscription$.pipe(
      filter((subscription) => !!subscription),
      timeout(this.timeout),
      map((subscription) => {
        if (subscription.customerStatusKey === 'customer.status.locked') {
          console.log('IsCustomerActiveGuard denied access - customer locked');

          return this.router.parseUrl('/sperre');
        }

        if (subscription.writtenOff) {
          return this.router.parseUrl('/konto-hinweis');
        }

        return true;
      }),
      catchError(() => {
        return of(true);
      }),
    );
  }

  /**
   * Interface that a class can implement to be a guard deciding if a child route can be activated
   */
  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
