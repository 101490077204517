import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';

@Injectable({
  providedIn: 'root',
})
export class HasSubscriptionGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.checkStore(state).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  checkStore(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(fromStore.payment.selectSubscriptionLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(fromStore.payment.loadSubscription());
        }
      }),
      filter((loaded) => loaded),
      take(1),
      switchMap(() =>
        this.store.select(fromStore.payment.selectSubscription).pipe(
          map((subscription) => {
            if (!subscription) {
              console.log('HasSubscriptionGuard denied access', {
                state,
              });

              return this.router.createUrlTree(['/fehler-paket']);
            }

            return true;
          }),
        ),
      ),
    );
  }
}
