import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@nowffc-environment/environment';
import { MigratePartnerTokenResponse } from '@nowffc-shared/interfaces/MigratePartnerTokenResponse';
import { RefreshResponse } from '@nowffc-shared/interfaces/refresh-response';
import { LoginResponse } from '@nowffc-shared/interfaces/response/login-response';
import { User } from '@nowffc-shared/interfaces/user';
import { UserService } from '@nowffc-shared/services/user/user.service';
import { fromAuthentication } from '@nowffc-state/auth';
import { isBefore, parseISO } from 'date-fns';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PartnerReference } from '../../interfaces/partner-reference';
import { VerifyPartnerTokenRequest } from '../../interfaces/VerifyPartnerTokenRequest';
import { VerifyPartnerTokenResponse } from '../../interfaces/VerifyPartnerTokenResponse';

export interface LoginSuccess {
  user: User;
  uid: number;
  accountPersonalizationId: string;
  deletionDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  registrationToken?: string;

  partnerReference?: PartnerReference;

  email?: string;

  currentMigrationResponse?: MigratePartnerTokenResponse;

  private isLoggedIn = false;

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly store: Store,
  ) {}

  isLoggedInWithOldLogin(): boolean {
    return this.isLoggedIn;
  }

  private static mapLoginResponseToUserObject(res: LoginResponse): User {
    let gender = res.gender;

    if (!gender) {
      if (res.sex === 'm') {
        gender = 'male';
      } else if (res.sex === 'f') {
        gender = 'female';
      } else {
        gender = 'unspecified';
      }
    }

    return {
      email: res.email,
      emailVerified: res.emailVerified,
      gender,
      firstname: res.firstname,
      lastname: res.lastname,
      dateOfBirth: res.birthday ? parseISO(res.birthday) : undefined,
      country: res.country,
    };
  }

  login(password: string): Observable<LoginSuccess> {
    return this.http
      .post<LoginResponse>(`${environment.authUrl}/login`, {
        email: this.email,
        password,
      })
      .pipe(
        tap(({ token }) => this.userService.storeAccessToken(token)),
        map((res) => {
          this.isLoggedIn = true;
          const { uid, accountPersonalizationId, deletionDate } =
            this.userService.getUserToken(res.token);

          return {
            user: PartnerService.mapLoginResponseToUserObject(res),
            uid,
            accountPersonalizationId,
            deletionDate,
          };
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 451) {
            return throwError(() => ({
              message: 'login.user.disabled',
            }));
          } else {
            return throwError(() => ({
              message: 'login.alert.wrongcredentials',
            }));
          }
        }),
      );
  }

  register(
    password: string,
    dateOfBirth: Date,
    receiveMarketingEmails: boolean,
  ): Observable<LoginSuccess> {
    return this.http
      .post<LoginResponse>(`${environment.authUrl}/register/partner`, {
        email: this.email,
        password,
        dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd', 'EN'),
        communicationSettings: {
          receiveMarketingEmails,
        },
        token: this.registrationToken,
      })
      .pipe(
        map((loginResponse) => {
          this.isLoggedIn = true;
          this.userService.storeAccessToken(loginResponse.token);
          const user =
            PartnerService.mapLoginResponseToUserObject(loginResponse);
          const { uid, accountPersonalizationId } =
            this.userService.getUserToken(loginResponse.token);

          this.store.dispatch(
            fromAuthentication.registerPartnerSuccess({
              user,
              uid,
              accountPersonalizationId,
            }),
          );

          return { user, uid, accountPersonalizationId };
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(
            fromAuthentication.registerPartnerFail({
              error: { message: error.error?.description },
            }),
          );
          return throwError(() => ({
            message:
              error.error?.description ?? 'backend.user.registration.failed',
          }));
        }),
      );
  }

  async updatePartnerReference(): Promise<boolean> {
    return await this.http
      .post<RefreshResponse>(`${environment.authUrl}/refresh`, null)
      .toPromise()
      .then(
        (res) => {
          if (!res!.partner) {
            return false;
          }

          this.partnerReference = {
            registrationToken: undefined,
            status: res!.partner.status,
          };

          return true;
        },
        () => {
          return false;
        },
      );
  }

  async verifyRegistrationToken(options: {
    email?: string;
    registrationToken: string;
  }): Promise<VerifyPartnerTokenResponse | false> {
    this.registrationToken = options.registrationToken;
    const params: VerifyPartnerTokenRequest = {
      registrationToken: options.registrationToken,
    };
    /* istanbul ignore else */
    if (options.email) {
      params.email = options.email;
    }
    try {
      const response = await this.http
        .post<VerifyPartnerTokenResponse>(
          `${environment.bffUrl}/partner/verifyToken`,
          params,
        )
        .toPromise();
      this.partnerReference = {
        registrationToken: options.registrationToken,
        status: response!.status,
      };
      return response!;
    } catch {
      return false;
    }
  }

  async migrateUser(): Promise<MigratePartnerTokenResponse> {
    this.currentMigrationResponse = await this.http
      .post<MigratePartnerTokenResponse>(
        `${environment.bffUrl}/partner/migrate`,
        {
          registrationToken: this.registrationToken,
        },
      )
      .toPromise();

    return this.currentMigrationResponse as MigratePartnerTokenResponse;
  }

  hasStarted() {
    return !!this.partnerReference;
  }

  isPartnerAccount(): Observable<boolean> {
    return this.http
      .get<{
        state: { status: string; userId: number };
      }>(`${environment.bffUrl}/partner/isPartner`)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  logout(): void {
    this.isLoggedIn = false;
  }

  checkTokenIsValid(nowJwt: string | null): boolean {
    if (!nowJwt) return false;
    const { exp } = this.userService.getUserToken(nowJwt);
    return !isBefore(new Date(exp * 1000), new Date());
  }
}
