import { createAction, props } from '@ngrx/store';
import { Subscription } from '@nowffc-shared/interfaces/subscription';

// load subscription
export const loadSubscription = createAction(
  '[Subscription] Load Subscription',
);
export const loadSubscriptionFail = createAction(
  '[Subscription] Load Subscription Fail',
  props<{ error: { message: string } }>(),
);
export const loadSubscriptionSuccess = createAction(
  '[Subscription] Load Subscription Success',
  props<{ subscription: Subscription | undefined }>(),
);
