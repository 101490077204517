import { Environment } from '@nowffc-shared/interfaces/environment';
import { LogLevel } from 'angular-auth-oidc-client';

/**
 * set Environment variables for Production
 */
export const environment: Environment = {
  name: 'prod',
  production: true,
  wwwUrl: 'https://plus.rtl.de',
  bffUrl: '/api',
  webBffUrl: 'https://bff.apigw.tvnow.de',
  authUrl: 'https://auth.tvnow.de',
  oidc: {
    clientId: 'rtlplus-ffc',
    logLevel: LogLevel.Error,
    configTimeout: 3000,
    stsServer: 'https://auth.rtl.de/auth/realms/rtlplus',
    postLoginRoute: '/login/success',
  },
  unleash: {
    url: 'https://ftpxy.plus.rtl.de/proxy/',
    clientKey: 'T32wbOXrvUDC6u01k7Ro',
    disableRefresh: true,
    appName: 'ffc-ui',
    timeoutInMs: 3000,
    allowOverride: false,
  },
  billwerk: {
    locale: 'de',
    publicApiKey: '5c51a76db3e87415f84ad2f9',
    bookingProviderReturnUrl: 'https://$$HOST$$/buchen/finalisieren',
    initialBookingProviderReturnUrl:
      'https://$$HOST$$/buchen/finalisieren/initial',
    changeProviderReturnUrl:
      'https://$$HOST$$/mein-paket/zahlungsdaten-aendern/finalisieren',
    paymentMethods: {
      iFrame: ['CreditCard:PayOne'],
      debitCard: 'Debit:PayOne',
    },
    style: {
      body: {
        backgroundColor: '#000000',
        fontFamily: '"RTL United Text",sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        color: '#fdfeff',
      },
      label: {
        color: '#fdfeff',
        fontSize: '13px',
        fontWeight: '300',
      },
      input: {
        color: '#fdfeff',
        backgroundColor: '#2c2e30',
        border: '1px solid #2c2e30',
        borderRadius: '0',
        fontSize: '16px',
        fontWeight: '300',
        padding: '6px 15px',
      },
      inputRequired: {
        borderLeftWidth: '0',
      },
      inputInvalid: {
        borderLeftColor: '#d4000d',
        borderLeftWidth: '5px',
      },
    },
  },
  googleTagManager: {
    'tvnow.de': {
      containerId: 'GTM-TXX93V4',
      authentication: 'bOjdTAq9WZW6rr40k2ot_Q',
      environment: 'env-1',
    },
    'rtl.de': {
      containerId: 'GTM-MF8RWFB',
      authentication: 'YGMgW4DR16em1v63dOVTUA',
      environment: 'env-1',
    },
    'aws-cbc.cloud': {
      containerId: 'GTM-MF8RWFB',
      authentication: 'YGMgW4DR16em1v63dOVTUA',
      environment: 'env-1',
    },
  },
  tracking: {
    trackingEndpoint: 'https://plus.rtl.de/log',
  },
  bugsnag: {
    key: '72b3aad4d63089f1c6e015943b4deee2',
    releaseStage: 'prod',
    endpoints: {
      notify: 'https://notify-bugs-fra1.rtl.de',
      sessions: 'https://session-bugs-fra1.rtl.de',
    },
  },
  storage: {
    keys: {
      cancelSubscription: 'cancelSubscription',
      cancellationPreview: 'cancellationPreview',
      deleteAccount: 'deleteAccount',
      progressbarFlow: 'progressBarFlow',
      progressbarSnapshot: 'progressBarSnapshot',
    },
  },
  externalScripts: {
    billwerk: 'https://selfservice.rtl.billwerk.com/subscription.js',
    loyJoy: 'https://stable.loyjoy.com/widget.js',
  },
  braze: {
    baseUrl: 'sdk.fra-01.braze.eu',
    apiKey: 'd71cae45-c312-4d25-9b2e-8c7fba1c3888',
  },
  seo: {
    preventIndexing: false,
  },
};
