import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  ActivationStart,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { VersionCheckService } from '@nowffc-shared/services/version-check/version-check.service';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { TrackingService } from '@nowffc-shared/services/tracking/tracking.service';
import { MetaService } from '@nowffc-shared/services/meta/meta.service';
import { ToastrService } from 'ngx-toastr';
import { KameleoonInitializerService } from '@nowffc-shared/services/kameleoon/kameleoon-initializer.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';
import { Observable } from 'rxjs';
import { HeaderComponent } from './modules/header/header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '@nowffc-shared/components/loading-spinner/loading-spinner.component';
import { FooterComponent } from './modules/footer/footer.component';
import { BrazeModule } from '@plus/ngx-braze-sdk';
import { fromAuthentication } from '@nowffc-state/auth';
import { AccountChatBotService } from '@nowffc-shared/services/account-chat-bot.service';
import { FeatureToggleService } from '@nowffc-shared/services/feature-toggle/feature-toggle.service';
import { UserService } from '@nowffc-shared/services/user/user.service';
import { UnleashService } from '@nowffc-shared/services/feature-toggle/unleash.service';
import { CUSTOMER_ENGAGEMENT_FEATURE_TOGGLES } from '@nowffc-shared/services/feature-toggle/unleash.types';
import { AuthService } from '@nowffc-auth/auth.service';

@Component({
  standalone: true,
  selector: 'now-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    HeaderComponent,
    NgIf,
    RouterOutlet,
    LoadingSpinnerComponent,
    FooterComponent,
    BrazeModule,
    AsyncPipe,
  ],
})
export class AppComponent implements OnInit {
  /**
   * whether to show Header
   */
  showHeader = true;

  /**
   * whether to show Footer
   */
  showFooter = true;

  /**
   * slug of the current route
   */
  routerSlug = '';

  /**
   * Indicates that application is ready
   */
  isReady = false;

  isLoggedIn$: Observable<boolean> = this.store
    .select(fromStore.auth.selectAuthenticationInitialized)
    .pipe(
      filter((initialized) => initialized),
      mergeMap(() => this.authService.isAuthenticated()),
    );

  originUrl$ = this.redirectService.originUrl$;

  showMenu$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(
      ({ url }) =>
        url.startsWith('/partner-aktivierung') || !url.startsWith('/partner'),
    ),
  );

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly trackingService: TrackingService,
    private readonly windowRef: WindowRef,
    private readonly versionCheck: VersionCheckService,
    private readonly toastrService: ToastrService,
    private readonly metaService: MetaService,
    private readonly kameleoonInitializerService: KameleoonInitializerService,
    private readonly accountChatbotService: AccountChatBotService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly unleashService: UnleashService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
  ) {
    this.trackingService.init();
    this.store.dispatch(fromAuthentication.authenticate());

    /* istanbul ignore next */
    this.router.events.subscribe((event) => {
      if (
        event instanceof ActivationStart &&
        event.snapshot.queryParams.hasOwnProperty('r')
      ) {
        const myParams = { ...event.snapshot.queryParams };
        this.redirectService.setRedirectUrl(event.snapshot.queryParams.r);

        delete myParams.r;
        this.router.navigate([this.windowRef.nativeWindow.location.pathname], {
          queryParams: myParams,
        });
      }

      if (
        event instanceof ActivationStart &&
        event.snapshot.queryParams.hasOwnProperty('originUrl')
      ) {
        const myParams = { ...event.snapshot.queryParams };
        this.redirectService.setOriginUrl(myParams.originUrl);

        delete myParams.originUrl;
        this.router.navigate([this.windowRef.nativeWindow.location.pathname], {
          queryParams: myParams,
        });
      }

      if (event instanceof ActivationEnd) {
        this.routerSlug = this.windowRef.nativeWindow.document.location.pathname
          .toLowerCase()
          .substr(1)
          .replace(/\/+/g, '-');
      }

      if (event instanceof NavigationEnd) {
        this.metaService.setTitleByRoute(event.url);
        this.metaService.setMetaDescriptionByRoute(event.url);
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.webview === '1') {
        this.showHeader = false;
        this.showFooter = false;
      }
    });
  }

  async ngOnInit() {
    this.metaService.resetRobots();

    await this.kameleoonInitializerService.initializeKameleoon();

    // This is not awaited because we don't want to hold up the app initialization.
    // Error handling is done within the Unleash service. The catch is just here
    // to be on the safe side.
    this.unleashService.init().catch((error) => {
      console.error(`Error while loading unleash: ${error}`);
    });

    this.initChatBot();
    this.isReady = true;
  }

  handleLogoClick(e: MouseEvent) {
    e.preventDefault();

    this.redirectService.returnToHome();
  }

  handleToLoginClicked(e: MouseEvent) {
    e.preventDefault();

    this.authService.goToLogin(false);
  }

  private initChatBot() {
    this.featureToggleService
      .isEnabled(CUSTOMER_ENGAGEMENT_FEATURE_TOGGLES.CHATBOT, '')
      .then((enableChatBot) => {
        if (enableChatBot) {
          this.accountChatbotService.init();
        }
      });
  }
}
