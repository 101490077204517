export enum CustomerSubscriptionState {
  NONE,
  FFC_FREE,
  FFC_TRIAL,
  FFC_PAY,
  FFC_IN_CANCELLATION,
  FFC_IN_DELETION,
  IAP,
  PARTNER,
  PARTNER_CONFLICT,
}
